import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../../api/server';

export const fetchOffer = createAsyncThunk(
  'offer/fetchOffer',
  async ({ id, token }) => {
    const response = await axiosInstance.get(
      `api/v2/slotOffers/${id}?populate=pedido`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    const offer = response.data.data;

    return offer;
  }
);
