import { createSlice } from '@reduxjs/toolkit';
import { createPayment } from './pago.actions';
import { handleError } from '../../../api/errors';

const pagoSlice = createSlice({
  name: 'pago',
  initialState: {
    isLoading: false,
    isSuccess: false,
    error: null
  },
  reducers: {
    resetError(state) {
      state.error = null;
      state.isLoading = false;
      state.isSuccess = false;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(createPayment.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.error = '';
      })
      .addCase(createPayment.rejected, (state, action) => {
        state.error = action.error.message;
        state.isLoading = false;
        state.isSuccess = false;
      })
      .addCase(createPayment.fulfilled, (state) => {
        state.isSuccess = true;
        state.error = '';
        state.isLoading = false;
      });
  }
});

export const { resetError } = pagoSlice.actions;

const { reducer } = pagoSlice;

export default reducer;
