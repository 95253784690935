import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../../../api/server';

export const createPayment = createAsyncThunk(
  'pago/createPayment',
  async ({ body, token }) => {
    await instance.post('process_payment', body, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }
);
