import { createSlice } from '@reduxjs/toolkit';
import { fetchOffer } from './offer.actions';

const offerSlice = createSlice({
  name: 'offer',
  initialState: {
    data: null,
    isLoading: false,
    error: null
  },
  extraReducers(builder) {
    builder
      .addCase(fetchOffer.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchOffer.rejected, (state, action) => {
        state.error = action.error.message;
        state.isLoading = false;
      })
      .addCase(fetchOffer.fulfilled, (state, action) => {
        state.data = action.payload;
        state.error = null;
        state.isLoading = false;
      });
  }
});

const { reducer } = offerSlice;

export default reducer;
