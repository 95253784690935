import React from 'react';
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { initMercadoPago } from '@mercadopago/sdk-react';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme/theme';
import Brick from './components/Brick';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

//test=TEST-bb76950d-60f8-4de8-a848-e008c2f28902
//prod=APP_USR-75c9bff8-b933-4b54-a0ea-797e3041190b

initMercadoPago('TEST-bb76950d-60f8-4de8-a848-e008c2f28902', {
  locale: 'es-AR'
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        {/* <Header /> */}
        <Routes>
          <Route path="/" element={<Brick />} />
          {/* <Route path="/pay" element={<Brick />} />
          <Route path="/success" element={<Header />} /> */}
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
