import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    common: {
      blanco: "#FFFFFF",
      gris: "#DDDDDD",
      grisClaro1: "#E4E4E4",
      grisClaro2: "#EBEBEB",
      grisClaro3: "#F1F1F1",
      grisClaro4: "#F8F8F8",
      principal: "#FF6934",
      principalClaro1: "#FF875D",
      principalClaro2: "#FFA585",
      principalClaro3: "#FFC3AE",
      principalClaro4: "#FFE1D6",
      secundario: "#5BC7C8",
      secundarioClaro1: "#7CD2D3",
      secundarioClaro2: "#9DDDDE",
      secundarioClaro3: "#BDE9E9",
      secundarioClaro4: "#DEF4F4",
      negro: "#000000",
      negroClaro1: "#121212",
      negroClaro2: "#424242",
      negroClaro3: "#757575",
      negroClaro4: "#999999",
      tercero: "#D6FF59",
      terceroClaro1: "#E7FF80",
      terceroClaro2: "#F1FFA1",
      terceroClaro3: "#F6FFC0",
      terceroClaro4: "#F9FFD9",
    },
    primary: {
      main: "#FF6934",
      dark: "#BF2D00",
      light: "#FFCAB8",
      contrastText: "#F8F8F8",
    },
    secondary: {
      main: "#DCDCDC",
      dark: "#575759",
      light: "#F2F2F0",
      contrastText: "#F8F8F8",
    },
    background: {
      default: "#FCFCFC",
    },
    text: {
      primary: "#1A1A19",
      secondary: "#DCDCDC",
    },
  },
  shape: {
    borderRadius: 10,
  },
  spacing: 2,
  components: {
    MuiTableCell: {
      head: {
        color: "#F8F8F8",
        fontFamily: "Arimo",
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: "large",
        },
      },
    },
  },
});
