import { combineReducers } from 'redux';
import offerReducer from './slices/offer/offer.slice';
import pagoReducer from './slices/pago/pago.slice';
import authReducer from './slices/auth/auth.slice';

export default combineReducers({
  offer: offerReducer,
  payment: pagoReducer,
  auth: authReducer
});
