import { Wallet } from '@mercadopago/sdk-react';
import React from 'react';

const Brick = () => {
  const searchParams = new URLSearchParams(window.location.search);

  const preferenceId = searchParams.get('pref_id');

  const customization = {
    checkout: {
      theme: {
        elementsColor: '#FC6934',
        headerColor: '#FC6934'
      }
    },
    visual: {
      borderRadius: '22px'
    }
  };

  return (
    <Wallet initialization={{ preferenceId }} customization={customization} />
  );
};

export default Brick;
